<template>
  <div class="flex flex-col min-h-screen bg-auth-background justify-between">
    <header>
      <nav
        class="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <NuxtLink class="flex lg:flex-1" :to="localePath({ name: 'index' })">
          <div class="-m-1.5 p-1.5">
            <span class="sr-only">Quo LLC</span>
            <img
              class="h-12 w-auto rounded"
              src="/logo/quo-logo-80.png"
              alt=""
            />
          </div>
        </NuxtLink>
        <div class="flex flex-1 justify-end items-center">
          <div class="">
            <Menu as="div" class="relative inline-block">
              <div>
                <MenuButton
                  id="layout-auth-menu-language-button"
                  class="inline-flex w-full justify-center py-2"
                >
                  <SvgIcon
                    name="globe-alt"
                    type="outline"
                    class="w-6 h-6 stroke-auth-switch-language-icon"
                  />
                </MenuButton>
              </div>
              <MenuItems
                class="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg"
              >
                <div class="py-1">
                  <MenuItem v-slot="{ active, close }">
                    <NuxtLink
                      :class="[
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm',
                      ]"
                      :to="switchLocalePath('en')"
                      ><span @click="close">English</span></NuxtLink
                    >
                  </MenuItem>
                  <MenuItem v-slot="{ active, close }">
                    <NuxtLink
                      :class="[
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm',
                      ]"
                      :to="switchLocalePath('de')"
                      ><span @click="close">Deutsch</span></NuxtLink
                    >
                  </MenuItem>
                </div>
              </MenuItems>
            </Menu>
          </div>
        </div>
      </nav>
    </header>
    <main class="sm:mx-auto sm:max-w-7xl px-6 lg:px-8">
      <slot></slot>
    </main>
    <footer>
      <div class="mx-auto max-w-7xl px-6 pb-6 pt-6 sm:pt-8 lg:px-8 lg:pt-10">
        <div class="border-t border-auth-footer-hr-border pt-4">
          <div class="flex justify-between">
            <div>
              <span class="text-xs leading-5 text-gray-500">
                &copy; {{ getYear(new Date()) }} Quo LLC.
                {{ $t('general.allRightsReserved') }}
              </span>
            </div>
            <div>
              <NuxtLink
                :to="localePath({ name: 'support' })"
                class="text-xs leading-5 text-gray-500 font-medium"
                >{{ $t('general.support') }}</NuxtLink
              >
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script setup>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { getYear } from 'date-fns'

const iconStore = useIconStore()

const switchLocalePath = useSwitchLocalePath()
const localePath = useLocalePath()

await useAsyncData('icons', async () => {
  const [icons] = await Promise.all([iconStore.fetchIcons()])
  return { icons }
})
</script>
